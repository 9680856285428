@font-face {
  font-family: shabnam;
  src: url(fonts/Shabnam.woff);
}
* {
  font-family: "shabnam";
}

#root {
  min-height: 100vh;
}

.navItem-custom {
  margin: 0px 12px;
}
.custom-a:hover {
  color: rgb(204, 149, 221) !important;
}
.container-custom {
  min-height: 100vh;
  position: relative;
}

.shap-bg {
  position: absolute;
  top: 10px;
  left: 0;
}
.custom-a {
  font-size: 20px;
  transition: .3s;
}
.custom-brand {
  font-size: 33px;
}
.video-box {
  margin-top: 250px !important;
  padding-bottom: 70px;
  width: 1300px;
  height: 600px;
  position: relative;
}

.videoo {
  /* width: 200px; */
  height: -webkit-fill-available;
  border: 10px solid hsl(80 100% 50%);
  border-image-slice: 1;

  border-image-source: conic-gradient(
    from 0deg,
    hsl(287.16deg 100% 50%),
    hsl(200deg 92.77% 44.11%),
    hsl(288.54deg 100% 50%)
  );

  clip-path: inset(0 round 24px);
}
.close-video {
  position: absolute;
  top: 25px;
  right: 25px;
  background-image: linear-gradient(
    to right,
    rgb(50, 15, 248),
    rgb(128, 3, 167)
  );
  width: auto;
  cursor: pointer !important;
  z-index: 50;
  color: #fff;
  border-radius: 5px;
}
.vv {
  text-align: center;
}
.img-about {
  width: 100%;
  height: 100%;
}
.img {
  text-align: center;
}
.custom-rule-p {
  text-align: justify;
}
.a-Custom {
  text-decoration: none;
}

.box-click {
  position: relative;
  color: #fff;
}
.box-click:hover {
  /* font-size: 18px; */
  color: #fff;
}
.box-click::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 5px;
  background: linear-gradient(
    45deg,
    rgba(23, 3, 138, 0.37),
    rgba(90, 3, 116, 0.37)
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.box {
  position: relative;
  color: #fff;
}
.box:hover {
  /* font-size: 18px; */
  font-weight: bold;
  color: #fff;
}
.box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 5px;
  background: linear-gradient(45deg, rgb(50, 15, 248), rgb(128, 3, 167));
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.custoom-navv {
  border: 0.4px solid #ffffff24 !important;
}
@media only screen and (max-width: 500px) {
  .custoom-navv {
    direction: ltr !important;
  }
}
